import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {
  GetProductReviewsPayload,
  ReviewProduct,
} from "../../../features/User/UserInterface";
import StarFilledSvg from "../../../components/SvgImages/StarFilledSvg/StarFilledSvg";
import StarFilledRatingSvg from "../../../components/SvgImages/StarFilledRatingSvg/StarFilledRatingSvg";
import StarUnfilledRatingSvg from "../../../components/SvgImages/StartUnfilledRatingSvg/StarUnfilledRatingSvg";
import "./ProductReviewsPage.css";
import {formatDistanceToNow} from "date-fns";
import {getProductReviewsThunk} from "../../../features/User/UserSlice";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../interceptors/store";
import {ProductById} from "../../../features/Inventory/InventoryInterface";
import {getProductThunk} from "../../../features/Inventory/InventorySlice";

export const ProductReviewsPage = () => {
  const [avgCustomerServiceRating, setAvgCustomerServiceRating] =
    useState<number>(0);
  const [product, setProduct] = useState<ProductById | null>(null);
  const [deliveryRating, setDeliveryRating] = useState<number>(0);
  const [qualityRating, setQualityRating] = useState<number>(0);
  const [reviews, setReviews] = useState<ReviewProduct[]>([]);
  const [reviewCount, setReviewCount] = useState<number>(0);
  const [, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  // const getReviews = async () => {
  //     const reviewAction = await dispatch(
  //         getStoreReviewsThunk({
  //             storeId: storeId || "",
  //         }),
  //     );
  //     if (getStoreReviewsThunk.fulfilled.match(reviewAction)) {
  //         const reviewDetails = reviewAction.payload.data.review_details;
  //         const reviewCount = reviewAction.payload.data.count;
  //         setReviews(reviewDetails);
  //         setReviewCount(reviewCount);
  //     }
  // };
  const {id} = useParams<{id: string}>();

  const fetchProduct = async () => {
    try {
      const payload = {
        product_id: id ?? "",
      };
      const action = await dispatch(getProductThunk(payload));

      if (getProductThunk.fulfilled.match(action)) {
        if (action?.payload?.product) {
          const temp = action.payload.product;
          setProduct(temp);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    if (id) {
      initiateGetProductReviews(1);
    }
  }, [id]);

  const initiateGetProductReviews = async (page: number) => {
    setLoading(true);
    await fetchProduct();
    await getProductReviews(page);
    setLoading(false);
  };

  const getProductReviews = async (page: number) => {
    const payload: GetProductReviewsPayload = {
      type: "product",
      limit: 10,
      page,
      product_id: id || "",
    };

    try {
      const action = await dispatch(getProductReviewsThunk(payload));
      if (getProductReviewsThunk.fulfilled.match(action)) {
        setReviews(action?.payload?.review_details?.reviews);
        calculateAvgCustomerServiceRating(
          action?.payload?.review_details?.reviews,
        );
        setReviewCount(action?.payload?.count);
      } else {
        console.log("Error fetching reviews");
      }
    } catch (error) {
      console.log("error fetching reviews", error);
    }
  };

  useEffect(() => {
    calculateAvgCustomerServiceRating(reviews);
  }, [id]);

  // const fetchProduct = async () => {
  //   try {
  //     const payload = {
  //       product_id: id ?? "",
  //     };
  //     const action = await dispatch(getProductThunk(payload));

  //     if (getProductThunk.fulfilled.match(action)) {
  //       if (action?.payload?.product) {
  //         const temp = action.payload.product;
  //         if (temp) {
  //           const tempProductPricingDetails: ProductPricingDetail[] = [];

  //           temp.productPricingDetails?.forEach((variant, index) => {
  //             tempProductPricingDetails.push({
  //               ...variant,
  //               product_price: variant.product_price,
  //               cost_price: variant.product_price,
  //               selling_price: variant.selling_price,
  //               varient_id: variant.id,
  //             });

  //             const defaultVariant = temp?.productPricingDetails[0];
  //             if (
  //               defaultVariant.variantValueDetails &&
  //               defaultVariant.variantValueDetails.length > 0
  //             ) {
  //               const tempSelectedVariantValue: {
  //                 [key: string]: string | number;
  //               } = {};

  //               defaultVariant.variantValueDetails.map(variantValue => {
  //                 if (variantValue?.varientCategory?.[0]?.varient_name) {
  //                   tempSelectedVariantValue[
  //                     variantValue?.varientCategory?.[0]?.varient_name
  //                   ] = variantValue.varient_value;
  //                 }
  //               });

  //               setSelectedVariantValue(tempSelectedVariantValue);
  //               setSelectedVariantIndex(index);
  //             }
  //           });

  //           temp["productPricingDetails"] = tempProductPricingDetails;
  //           if (temp.product_videos.length > 0) {
  //             temp["productImageDetails"].splice(1, 0, temp.product_videos[0]);
  //           }

  //           setProduct(temp);
  //         }
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };
  //   const {reviews, reviewCount, storeRating} = useLocation().state;

  const calculateAvgCustomerServiceRating = (reviews: ReviewProduct[]) => {
    const customerServiceRatings = reviews
      .flatMap(review => review.rating)
      .filter(rating => rating.rating_type === "customer_service");

    const deliveryRatings = reviews
      .flatMap(review => review.rating)
      .filter(rating => rating.rating_type === "packaging");

    const qualityRatings = reviews
      .flatMap(review => review.rating)
      .filter(rating => rating.rating_type === "quality");

    // Calculate total rating and average
    const totalCustomerServiceRating = customerServiceRatings.reduce(
      (sum, rating) => sum + rating.rating,
      0,
    );
    const avgCustomerServiceRating =
      customerServiceRatings.length > 0
        ? Math.floor(totalCustomerServiceRating / customerServiceRatings.length)
        : 0; // Avoid division by zero

    const totalDeliveryRating = deliveryRatings.reduce(
      (sum, rating) => sum + rating.rating,
      0,
    );
    const avgDeliveryRating =
      deliveryRatings.length > 0
        ? Math.floor(totalDeliveryRating / deliveryRatings.length)
        : 0;

    const totalQualityRating = qualityRatings.reduce(
      (sum, rating) => sum + rating.rating,
      0,
    );
    const avgQualityRating =
      qualityRatings.length > 0
        ? Math.floor(totalQualityRating / qualityRatings.length)
        : 0;

    setAvgCustomerServiceRating(avgCustomerServiceRating);
    setDeliveryRating(avgDeliveryRating);
    setQualityRating(avgQualityRating);
  };

  const formattedDate = (created_at: string) => {
    return formatDistanceToNow(new Date(created_at), {addSuffix: true});
  };

  return (
    <div className="all-reviews-page">
      <div className="all-reviews-page-header">
        <div className="all-reviews-page-header-rating">
          <div className="all-reviews-page-header-rating-star">
            <StarFilledSvg height={"22"} width={"22"} />
          </div>
          <div className="all-reviews-page-header-rating-text">
            {product?.product_rating || "N/A"}
          </div>
        </div>
        <div className="all-reviews-page-header-rating-separator">•</div>
        <div className="all-reviews-page-header-rating-summary">
          {reviewCount || "N/A"} Reviews
        </div>
      </div>
      <div className="all-reviews-page-avg-rating">
        <div className="all-reviews-page-avg-rating-container">
          <div className="all-reviews-page-avg-rating-left">
            <span className="all-reviews-page-avg-rating-left-text">
              Customer Service
            </span>
          </div>
          <div className="all-reviews-page-avg-rating-right">
            <div className="all-review-page-about-section-reviews-card-heading-rating-star">
              {Array.from({length: avgCustomerServiceRating}).map(
                (_, index) => (
                  <StarFilledRatingSvg
                    key={index}
                    height={16}
                    width={16}
                    color="var(--content-active)"
                  />
                ),
              )}
              {Array.from({length: 5 - avgCustomerServiceRating}).map(
                (_, index) => (
                  <StarUnfilledRatingSvg
                    key={index}
                    height={16}
                    width={16}
                    color="var(--content-inactive)"
                  />
                ),
              )}
            </div>
            <span className="all-reviews-page-avg-rating-right-text">
              {avgCustomerServiceRating}
            </span>
          </div>
        </div>
        <div className="all-reviews-page-avg-rating-container">
          <div className="all-reviews-page-avg-rating-left">
            <span>Delivery</span>
          </div>
          <div className="all-reviews-page-avg-rating-right">
            <div className="all-review-page-about-section-reviews-card-heading-rating-star">
              {Array.from({length: deliveryRating}).map((_, index) => (
                <StarFilledRatingSvg
                  key={index}
                  height={16}
                  width={16}
                  color="var(--content-active)"
                />
              ))}
              {Array.from({length: 5 - deliveryRating}).map((_, index) => (
                <StarUnfilledRatingSvg
                  key={index}
                  height={16}
                  width={16}
                  color="var(--content-inactive)"
                />
              ))}
            </div>
            <span>{deliveryRating}</span>
          </div>
        </div>
        <div className="all-reviews-page-avg-rating-container">
          <div className="all-reviews-page-avg-rating-left">
            <span>Quality</span>
          </div>
          <div className="all-reviews-page-avg-rating-right">
            <div className="all-review-page-about-section-reviews-card-heading-rating-star">
              {Array.from({length: qualityRating}).map((_, index) => (
                <StarFilledRatingSvg
                  key={index}
                  height={16}
                  width={16}
                  color="var(--content-active)"
                />
              ))}
              {Array.from({length: 5 - qualityRating}).map((_, index) => (
                <StarUnfilledRatingSvg
                  key={index}
                  height={16}
                  width={16}
                  color="var(--content-inactive)"
                />
              ))}
            </div>
            <span>{qualityRating}</span>
          </div>
        </div>
        <div className="all-reviews-page-reviews-container">
          {reviews.map((review: ReviewProduct, index: number) => (
            <div
              className="all-review-page-about-section-reviews-card"
              key={index}>
              <div className="all-review-page-about-section-reviews-card-heading">
                <div className="all-review-page-about-section-reviews-card-heading-rating-star">
                  {Array.from({length: Math.floor(review.avg_rating)}).map(
                    (_, index) => (
                      <StarFilledRatingSvg
                        key={index}
                        height={12}
                        width={12}
                        color="var(--content-active)"
                      />
                    ),
                  )}
                </div>
                <div className="all-review-page-about-section-reviews-card-heading-rating-text">
                  {review.review_text}
                </div>
              </div>
              <div className="all-review-page-about-section-reviews-card-reviewer">
                <div className="all-review-page-about-section-reviews-card-reviewer-avatar-container">
                  <img
                    src={
                      review.profile_photo ||
                      "https://storage.googleapis.com/zoop-public-bucket/ZOOP-RECOMMENDS/productstock.png"
                    }
                    alt="Reviewer Profile"
                    className="all-review-page-about-section-reviews-card-reviewer-avatar"
                  />
                </div>
                <div className="all-review-page-about-section-reviews-card-reviewer-name-container">
                  <div className="all-review-page-about-section-reviews-card-reviewer-name">
                    {review.name}
                  </div>
                  <div className="all-review-page-about-section-reviews-card-review-date">
                    {formattedDate(review.created_at)}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
