/* eslint-disable @typescript-eslint/no-explicit-any */
import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {findByKey, findIndexByKey} from "../../utils/helpers";
import {current} from "@reduxjs/toolkit";
import {
  getCategoriesApi,
  getCityStateByPincode,
  getStoreFrontAboutApi,
  getStoreFrontDetailsApi,
  getSubCategoriesApi,
  getStoreRatingCountApi,
  getStoreReviewDetailsApi,
  getUserData,
  updateUserData,
  getFeaturedStoresApi,
  getZoopRecommendsApi,
  getProductReviewsApi,
  postFollowUnfollowApi,
} from "./UserApi";
import {
  AddAddressPayload,
  Address,
  GetCityStateByPincodePayload,
  GetProductReviewsPayload,
  GetStoreFrontDetailsResponse,
  GetZoopRecommendsPayload,
  SetSelectedAddress,
  UserState,
  PostFollowUnfollowPayload,
  PostFollowUnfollowResponse,
} from "./UserInterface";

const initialState: UserState = {
  loading: false,
  error: null,
  success: false,
  user: null,
  selectedAddress: null,
  storeFront: {details: null, about: null},
  categories: [],
  featuredStores: [],
  zoopRecommends: [],
};

export const getUserThunk = createAsyncThunk(
  "user/getUser",
  async (_, {rejectWithValue}) => {
    try {
      const response = await getUserData();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const getCityStateByPincodeThunk = createAsyncThunk(
  "user/getCityStateByPincode",
  async (payload: GetCityStateByPincodePayload, {rejectWithValue}) => {
    try {
      const response = await getCityStateByPincode(payload);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const addAddressThunk = createAsyncThunk(
  "user/addAddressThunk",
  async (payload: AddAddressPayload, {rejectWithValue}) => {
    try {
      const response = await updateUserData(payload);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const getStoreFrontDetailsThunk = createAsyncThunk<
  GetStoreFrontDetailsResponse, // The type of data returned when fulfilled
  {storeId: string; type: string; store_name: string}, // The argument types
  {rejectValue: string} // The reject type
>(
  "user/store/getStoreDetails",
  async ({storeId, type, store_name}, {rejectWithValue}) => {
    try {
      const response = await getStoreFrontDetailsApi(storeId, type, store_name);

      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const getStoreFrontAboutThunk = createAsyncThunk(
  "user/store/getStoreAbout",
  async (
    {storeId, type}: {storeId: string; type: string},
    {rejectWithValue},
  ) => {
    try {
      const response = await getStoreFrontAboutApi(storeId, type);

      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const setSelectedAddressThunk = createAsyncThunk<
  any,
  SetSelectedAddress,
  {rejectValue: string} // The reject type
>("user/setAddress", async (payload, {rejectWithValue}) => {
  try {
    return payload;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

export const getCategoriesThunk = createAsyncThunk(
  "user/getCategories",
  async (_, {rejectWithValue}) => {
    try {
      const response = await getCategoriesApi();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const getSubCategoriesThunk = createAsyncThunk(
  "user/getSubCategories",
  async (categoryId: number, {rejectWithValue}) => {
    try {
      const response = await getSubCategoriesApi(categoryId);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const getStoreReviewsThunk = createAsyncThunk(
  "user/getStoreReview",
  async ({storeId}: {storeId: string}, {rejectWithValue}) => {
    try {
      const response = await getStoreReviewDetailsApi(storeId);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const getStoreRatingCountThunk = createAsyncThunk(
  "user/getStoreRatingCount",
  async ({storeId}: {storeId: string}, {rejectWithValue}) => {
    try {
      const response = await getStoreRatingCountApi(storeId);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const getFeaturedStoresThunk = createAsyncThunk(
  "user/getFeaturedStores",
  async (payload: GetZoopRecommendsPayload, {rejectWithValue}) => {
    try {
      const response = await getFeaturedStoresApi(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const getZoopRecommendsThunk = createAsyncThunk(
  "user/getZoopRecommends",
  async (payload: GetZoopRecommendsPayload, {rejectWithValue}) => {
    try {
      const response = await getZoopRecommendsApi(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const getProductReviewsThunk = createAsyncThunk(
  "user/getProductReviews",
  async (payload: GetProductReviewsPayload, {rejectWithValue}) => {
    try {
      const response = await getProductReviewsApi(payload);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const postFollowUnfollowThunk = createAsyncThunk(
  "user/postFollowUnfollow",
  async (payload: PostFollowUnfollowPayload, {rejectWithValue}) => {
    try {
      const response: PostFollowUnfollowResponse =
        await postFollowUnfollowApi(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    reset: () => initialState,
    setSelectedAddress: (state, action) => {
      const {addressId} = action.payload;
      const originalState: any = current(state);

      const address = findByKey(
        [...originalState.user.address],
        "id",
        addressId,
      );

      // Only update `selectedAddress` if the address is found
      if (address) {
        state.selectedAddress = address;
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getUserThunk.pending, state => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getUserThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.user;

        if (!state.selectedAddress && state.user?.address?.length) {
          state.selectedAddress = state.user.address[0];
        }
        localStorage.setItem("firstName", state.user?.first_name ?? "");
        localStorage.setItem("lastName", state.user?.last_name ?? "");
        localStorage.setItem("email", state.user?.email ?? "");
        localStorage.setItem("username", state.user?.user_name ?? "");
      })
      .addCase(getUserThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getCityStateByPincodeThunk.pending, state => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getCityStateByPincodeThunk.fulfilled, state => {
        state.loading = false;
        state.error = null;
        state.success = true;
      })
      .addCase(getCityStateByPincodeThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.success = false;
      })
      .addCase(addAddressThunk.pending, state => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(addAddressThunk.fulfilled, (state, action) => {
        state.loading = false;

        const addedAddress = Array.isArray(action.payload.user_address_response)
          ? action.payload.user_address_response?.[0]
          : action.payload.user_address_response;

        if (state.user && addedAddress) {
          const userAddresses: Address[] = state.user?.address ?? [];
          if (userAddresses) {
            const tempAddressIndex = findIndexByKey(
              userAddresses,
              "id",
              addedAddress.id,
            );

            if (tempAddressIndex !== -1) {
              userAddresses[tempAddressIndex] = addedAddress;
            } else {
              userAddresses.push(addedAddress);
            }
          }

          state.user.address = userAddresses ?? [];
          state.selectedAddress =
            state.user?.address.find(
              address => address.id === addedAddress.id,
            ) || null;
        }

        state.error = null;
      })
      .addCase(addAddressThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.success = false;
      })
      .addCase(getStoreFrontDetailsThunk.pending, state => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getStoreFrontDetailsThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.storeFront.details = action.payload.data;
      })
      .addCase(getStoreFrontDetailsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getStoreFrontAboutThunk.pending, state => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getStoreFrontAboutThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.storeFront.about = action.payload.data;
      })
      .addCase(getStoreFrontAboutThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(setSelectedAddressThunk.pending, state => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(setSelectedAddressThunk.fulfilled, (state, action) => {
        state.loading = false;
        const {addressId} = action.payload;

        if (state?.user?.address) {
          const address = findByKey([...state.user.address], "id", addressId);

          // Only update `selectedAddress` if the address is found
          if (address) {
            state.selectedAddress = address;
          }
        }
      })
      .addCase(setSelectedAddressThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Get Categories
      .addCase(getCategoriesThunk.pending, state => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getCategoriesThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.categories = action.payload.categories;
        state.success = true;
      })
      .addCase(getCategoriesThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // Get Sub Categories
      .addCase(getSubCategoriesThunk.pending, state => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getSubCategoriesThunk.fulfilled, state => {
        state.loading = false;
        state.error = null;
        state.success = true;
      })
      .addCase(getSubCategoriesThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(getStoreReviewsThunk.pending, state => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getStoreReviewsThunk.fulfilled, state => {
        state.loading = false;
      })
      .addCase(getStoreReviewsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(getStoreRatingCountThunk.pending, state => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getStoreRatingCountThunk.fulfilled, state => {
        state.loading = false;
      })
      .addCase(getStoreRatingCountThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(getProductReviewsThunk.pending, state => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getProductReviewsThunk.fulfilled, state => {
        state.loading = false;
      })
      .addCase(getProductReviewsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      // Get Featured Stores
      .addCase(getFeaturedStoresThunk.pending, state => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getFeaturedStoresThunk.fulfilled, (state, action) => {
        state.loading = false;

        const featuredStores = action.payload.data;
        state.featuredStores = featuredStores.result;

        state.error = null;
        state.success = true;
      })
      .addCase(getFeaturedStoresThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // Get Zoop Recommends
      .addCase(getZoopRecommendsThunk.pending, state => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getZoopRecommendsThunk.fulfilled, (state, action) => {
        state.loading = false;

        const zoopRecommends = action.payload.data;
        state.zoopRecommends = zoopRecommends.result;

        state.error = null;
        state.success = true;
      })
      .addCase(getZoopRecommendsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // follow unfollow post api
      .addCase(postFollowUnfollowThunk.pending, state => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(postFollowUnfollowThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.success = true;

        if (state.storeFront?.about) {
          state.storeFront.about.is_store_followed =
            action.meta?.arg?.action !== "unfollow";
        }
      })
      .addCase(postFollowUnfollowThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default userSlice.reducer;
export const {reset} = userSlice.actions;
