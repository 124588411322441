import React from "react";
import {SvgProps} from "../ChatSvg/ChatSvg";

const PhoneIconSvg: React.FC<SvgProps> = ({width, height, color}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 20 20">
      <g clipPath="url(#clip0_60_31179)">
        <path
          fill={color ? color : "#6B6B6B"}
          d="m17.373 12.38-3.68-1.65-.011-.004a1.25 1.25 0 0 0-1.244.153l-1.901 1.62c-1.205-.584-2.449-1.819-3.034-3.008l1.624-1.93.044-.06a1.25 1.25 0 0 0 .103-1.177v-.01L7.62 2.629a1.25 1.25 0 0 0-1.298-.744A4.395 4.395 0 0 0 2.5 6.25c0 6.203 5.047 11.25 11.25 11.25a4.395 4.395 0 0 0 4.366-3.822 1.25 1.25 0 0 0-.743-1.299m-3.623 3.87a10.01 10.01 0 0 1-10-10 3.14 3.14 0 0 1 2.724-3.125v.01l1.64 3.671L6.5 8.74a1.25 1.25 0 0 0-.123 1.287c.708 1.447 2.167 2.895 3.63 3.602a1.25 1.25 0 0 0 1.288-.133l1.9-1.62 3.671 1.644h.009a3.14 3.14 0 0 1-3.125 2.73"></path>
      </g>
      <defs>
        <clipPath id="clip0_60_31179">
          <path fill="#fff" d="M0 0h20v20H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};
export default PhoneIconSvg;
