import React from "react";

export interface SvgProps {
  height: number | string;
  width: number | string;
  color?: string;
}

const StarFilledSvg: React.FC<SvgProps> = ({height, width, color}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 20 20">
      <g clipPath="url(#clip0_60_30824)">
        <path
          fill={color ? color : "#141414"}
          d="m18.304 8.973-3.516 3.033 1.071 4.537a1.281 1.281 0 0 1-1.914 1.392L10 15.507l-3.948 2.428a1.28 1.28 0 0 1-1.911-1.392l1.075-4.537L1.7 8.973a1.286 1.286 0 0 1 .728-2.254l4.61-.372 1.778-4.303a1.278 1.278 0 0 1 2.364 0l1.778 4.303 4.61.372a1.286 1.286 0 0 1 .731 2.255z"></path>
      </g>
      <defs>
        <clipPath id="clip0_60_30824">
          <path fill={color ? color : "#fff"} d="M0 0h20v20H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default StarFilledSvg;
