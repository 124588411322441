import React, {useEffect} from "react";
import {useNavigate, useLocation, matchPath} from "react-router-dom";
import useMobileSize from "./hooks/useMobileSize";
import "./App.css";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {useDispatch, useSelector} from "react-redux";
import "promise-polyfill/src/polyfill";
import {
  // getCategoriesThunk,
  getFeaturedStoresThunk,
  getUserThunk,
  getZoopRecommendsThunk,
} from "./features/User/UserSlice";
import {setAuthentication} from "./features/Authentication/AuthenticationSlice";
import ReactGA from "react-ga4";
import {getLiveStreamsThunk} from "./features/LiveStream/LiveStreamSlice";
import {AppDispatch, RootState} from "./interceptors/store";

import Header from "./components/mobile/Header/Header";

import cookieUtils from "./utils/cookies";

import RoutesConfig from "./routes-config";
import {useStorefrontDetection} from "./hooks/useStoreFrontDetection";
import "./theme/global.css";
const excludeHeader: string[] = ["/login", "/stream/:id/live"];

// Initialize Google Analytics with your Tracking ID
ReactGA.initialize(process.env.REACT_APP_TRACKING_NUMBER);

const App: React.FC = () => {
  const isMobile = useMobileSize(1000);
  const dispatch = useDispatch<AppDispatch>();
  const {isSignedIn} = useSelector((state: RootState) => state.authentication);
  const navigate = useNavigate();
  const location = useLocation();
  const isStoreFront = useStorefrontDetection();

  useEffect(() => {
    onPageLoad();
  }, [location.pathname]);

  const onPageLoad = () => {
    if (cookieUtils.getCookie("token")) {
      dispatch(setAuthentication(true));
    }

    fetchProductRecommends();
    fetchLiveStreams();
    fetchFeaturedStores();
  };

  const fetchLiveStreams = async () => {
    try {
      const action = await dispatch(getLiveStreamsThunk({}));
      if (getLiveStreamsThunk.fulfilled.match(action)) {
        // Success handling here
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchProductRecommends = async () => {
    try {
      const action = await dispatch(
        getZoopRecommendsThunk({key_name: "product_pricing_id", limit: 8}),
      );
      if (getZoopRecommendsThunk.fulfilled.match(action)) {
        // Success handling here
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchFeaturedStores = async () => {
    try {
      const action = await dispatch(
        getFeaturedStoresThunk({key_name: "store_id", limit: 8}),
      );
      if (getFeaturedStoresThunk.fulfilled.match(action)) {
        // Success handling here
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (isSignedIn) {
      fetchUser();
    }
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
      isSignedIn,
      isMobile,
      userId: localStorage.getItem("userId") || null,
    });
  }, [isSignedIn]);

  const fetchUser = async () => {
    try {
      const action = await dispatch(getUserThunk());
      if (getUserThunk.fulfilled.match(action)) {
        const data = action.payload?.user;

        if (
          data &&
          data.first_name &&
          data.last_name &&
          data.email &&
          data.user_name
        ) {
          localStorage.setItem("username", data?.user_name);
          localStorage.setItem("showChat", "true");
          localStorage.setItem("switchMobileView", "true");
          if (location.pathname === "/") {
            navigate("/");
          }
        }
      } else {
        toast.error("Error fetching user");
        dispatch(setAuthentication(false));
      }
    } catch {
      toast.error("Error fetching user");
      dispatch(setAuthentication(false));
    }
  };

  const shouldRenderHeader = !excludeHeader.some(path =>
    matchPath({path, end: true}, location.pathname),
  );

  const renderMobileView = !["/", "/product/:id", "/products", "/search"].some(
    path => matchPath({path, end: true}, location.pathname),
  );

  return (
    <div className={renderMobileView ? "main-wrapper" : ""}>
      {shouldRenderHeader && !isStoreFront && <Header key="header-menu" />}
      <RoutesConfig /> {/* Use RoutesConfig here */}
    </div>
  );
};

export default App;
