import React from "react";

export interface SvgProps {
  height: number | string;
  width: number | string;
  color?: string;
}

const RightArrowSvg: React.FC<SvgProps> = ({height, width, color}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g id="CaretRight">
        <path
          d="M11.5306 8.5306L6.53063 13.5306C6.38973 13.6715 6.19864 13.7506 5.99938 13.7506C5.80012 13.7506 5.60902 13.6715 5.46813 13.5306C5.32723 13.3897 5.24808 13.1986 5.24808 12.9993C5.24808 12.8001 5.32723 12.609 5.46813 12.4681L9.9375 7.99997L5.46938 3.5306C5.39961 3.46083 5.34427 3.37801 5.30652 3.28686C5.26876 3.19571 5.24933 3.09801 5.24933 2.99935C5.24933 2.90069 5.26876 2.80299 5.30652 2.71184C5.34427 2.62069 5.39961 2.53786 5.46938 2.4681C5.53914 2.39833 5.62197 2.34299 5.71312 2.30524C5.80427 2.26748 5.90197 2.24805 6.00063 2.24805C6.09929 2.24805 6.19699 2.26748 6.28814 2.30524C6.37929 2.34299 6.46211 2.39833 6.53188 2.4681L11.5319 7.4681C11.6017 7.53786 11.6571 7.62072 11.6948 7.71193C11.7326 7.80313 11.752 7.9009 11.7518 7.99961C11.7517 8.09832 11.7321 8.19604 11.6942 8.28715C11.6562 8.37827 11.6006 8.461 11.5306 8.5306Z"
          fill={color ? color : "#484599"}
        />
      </g>
    </svg>
  );
};

export default RightArrowSvg;
