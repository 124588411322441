import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {RootState} from "../../../../interceptors/store";
import {logoutUser} from "../../../../utils/helpers";
import cookieUtils from "../../../../utils/cookies";
import {toast} from "react-toastify";
import "./LoginPhoneUserDetails.style.css";
import ReactGA from "react-ga4";
import {updateUserData} from "../../../../features/User/UserApi";
import Loader from "../../../../components/CustomLoader/CustomLoader";
interface Payload {
  first_name: string;
  last_name: string;
  email: string;
  user_name: string;
}

export const LoginPhoneUserDetails = ({onSuccess}: {onSuccess: () => void}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [username, setUsername] = useState<string>(
    localStorage.getItem("username") || "",
  );
  const [firstName, setFirstName] = useState<string>(
    localStorage.getItem("firstName") || "",
  );
  const [lastName, setLastName] = useState<string>(
    localStorage.getItem("lastName") || "",
  );
  const [email, setEmail] = useState<string>(
    localStorage.getItem("email") || "",
  );
  const user = useSelector((state: RootState) => state.user.user);
  const location = useLocation();
  // const navigate = useNavigate();

  useEffect(() => {
    if (!cookieUtils.getCookie("token")) {
      logoutUser();
    }
  }, []);

  useEffect(() => {
    if (user) {
      setFirstName(user.first_name || "");
      setLastName(user.last_name || "");
      setUsername(user.user_name || "");
      setEmail(user.email || "");
    }
  }, [user]);

  const validatePayload = (payload: Payload) => {
    const {first_name, last_name, email, user_name} = payload;

    if (!first_name || !last_name || !email || !user_name) {
      return {isValid: false, message: "All fields are required."};
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      return {isValid: false, message: "Invalid email format."};
    }

    const usernameRegex = /^\S{3,}$/;
    if (!usernameRegex.test(user_name)) {
      return {
        isValid: false,
        message:
          "Invalid username format. Must be at least 3 characters and contain no spaces.",
      };
    }

    return {isValid: true};
  };

  const handleUsernameSubmit = async () => {
    setLoading(true);
    if (username.trim() && firstName && lastName && email) {
      const payload: Payload = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        user_name: username,
      };

      const validation = validatePayload(payload);

      if (!validation.isValid) {
        ReactGA.event({
          category: "User",
          action: "UpdateUserDetailsError",
          label: "Validation Error",
          value: 1,
          nonInteraction: true,
          // @ts-expect-error: Ignoring the type check for custom dimensions
          dimension1: location.pathname + location.search,
          dimension2: localStorage.getItem("userId") || "",
          dimension3: validation.message,
        });
        toast.error(validation.message);
        return;
      }

      try {
        ReactGA.event({
          category: "User",
          action: "UpdateUserDetails",
          label: location.pathname + location.search,
          value: 1,
          nonInteraction: true,
          // @ts-expect-error: Ignoring the type check for custom dimensions
          dimension1: localStorage.getItem("userId") || "",
          dimension2: JSON.stringify(payload),
        });
        const data = await updateUserData({user_info: payload});

        if (data.success) {
          // toast.success("User information updated successfully");
          localStorage.setItem("username", username);
          localStorage.setItem("showChat", "true");
          localStorage.setItem("switchMobileView", "true");
          // navigate(-1);
          onSuccess();
        } else {
          ReactGA.event({
            category: "User",
            action: "UpdateUserDetailsError",
            label: "Api Error",
            value: 1,
            nonInteraction: true,
            // @ts-expect-error: Ignoring the type check for custom dimensions
            dimension1: location.pathname + location.search,
            dimension2: localStorage.getItem("userId") || "",
            dimension3: JSON.stringify(payload),
            dimension4: JSON.stringify(data),
          });
          // toast.error("Error updating user information");
        }
      } catch (error) {
        toast.error(JSON.stringify(error));
      }
    } else {
      toast.error("Please fill all fields.");
    }
    setLoading(false);
  };

  return (
    <div className="login-phone-user-details-container">
      <div className="login-phone-user-details-header">
        <span className="login-phone-user-details-header-text">
          What should we call you?
        </span>
      </div>
      <div className="login-phone-user-details-body">
        <span className="login-phone-user-details-body-text">Username</span>
        <div className="login-phone-user-details-body-input-container">
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={e => setUsername(e.target.value)}
            className="login-phone-user-details-body-input"
          />
        </div>
        <span className="login-phone-user-details-body-text">First Name</span>
        <div className="login-phone-user-details-body-input-container">
          <input
            type="text"
            placeholder="First Name"
            value={firstName}
            onChange={e => setFirstName(e.target.value)}
            className="login-phone-user-details-body-input"
          />
        </div>
        <span className="login-phone-user-details-body-text">Last Name</span>
        <div className="login-phone-user-details-body-input-container">
          <input
            type="text"
            placeholder="Last Name"
            value={lastName}
            onChange={e => setLastName(e.target.value)}
            className="login-phone-user-details-body-input"
          />
        </div>
        <span className="login-phone-user-details-body-text">Email</span>
        <div className="login-phone-user-details-body-input-container">
          <input
            type="text"
            placeholder="Email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            className="login-phone-user-details-body-input"
          />
        </div>
      </div>
      <div className="login-phone-user-details-continue-button-container">
        <button
          className="login-phone-user-details-continue-button"
          onClick={handleUsernameSubmit}
          disabled={!username || !firstName || !lastName}>
          {loading ? <Loader size={14} /> : "Continue"}
        </button>
      </div>
    </div>
  );
};
