/* eslint-disable @typescript-eslint/no-explicit-any */
import {api, apiConfig} from "../../interceptors/api";
import {
  GetProductResponse,
  GetProductsByFilterPayload,
  GetProductsByFilterResponse,
  GetProductSkuResponse,
  GetProductsPayload,
  GetProductsResponse,
  GetWishlistsApiParams,
  GetWishlistsApiResponse,
  SearchApiPayload,
  SearchApiResponse,
} from "./ProductInterface";

export const getProducts = async (
  payload: GetProductsPayload,
): Promise<GetProductsResponse> => {
  try {
    const response = await api.get(
      `${apiConfig.inventoryUrl}/api/inventory/public/product`,
      {
        params: payload,
      },
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch departments data :" + error);
  }
};

export const getProductById = async (
  productId: number,
): Promise<GetProductResponse> => {
  try {
    const response = await api.get(
      `${apiConfig.inventoryUrl}/api/inventory/product/${productId}`,
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.response.data.message || "Failed to fetch product");
  }
};

export const getProductSkuById = async (
  productSkuId: number,
): Promise<GetProductSkuResponse> => {
  try {
    const response = await api.get(
      `${apiConfig.inventoryUrl}/api/inventory/public/product`,
      {
        params: {
          product_pricing_ids: productSkuId,
        },
      },
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.response.data.message || "Failed to fetch product");
  }
};

export const searchApi = async (
  payload: SearchApiPayload,
): Promise<SearchApiResponse> => {
  try {
    const response = await api.get(
      `${apiConfig.inventoryUrl}/api/inventory/search`,
      {
        params: {
          ...payload,
        },
      },
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.response.data.message || "Failed to fetch products");
  }
};

export const getProductsByFilterApi = async (
  payload: GetProductsByFilterPayload,
): Promise<GetProductsByFilterResponse> => {
  try {
    const response = await api.get(
      `${apiConfig.inventoryUrl}/api/inventory/public/product-filter`,
      {
        params: payload,
      },
    );
    console.log("response here", response.data);

    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const getWishlistsApi = async (
  params: GetWishlistsApiParams = {},
): Promise<GetWishlistsApiResponse> => {
  try {
    const response = await api.get(`${apiConfig.inventoryUrl}/api/wishlist`, {
      params,
    });
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
};
