/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from "react";
import "./ProductDetails.css";
import {useLocation, useNavigate, useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {setAuthentication} from "../../features/Authentication/AuthenticationSlice";
import {getProductThunk} from "../../features/Inventory/InventorySlice";
import Loader from "../../Loader/Loader";
import {convertIntoINR, findByKey} from "../../utils/helpers";
import cookieUtils from "../../utils/cookies";
import {colourCode} from "../../constants/colourCode";
import {AppDispatch, RootState} from "../../interceptors/store";
import {
  ProductById,
  ProductPricingDetail,
} from "../../features/Inventory/InventoryInterface";
import {AddProductToCartPayload} from "../../features/Cart/CartInterface";
import {addProductToCartThunk} from "../../features/Cart/CartSlice";
import {
  GetProductReviewsPayload,
  ReviewProduct,
} from "../../features/User/UserInterface";
import {getProductReviewsThunk} from "../../features/User/UserSlice";
import useMobileSize from "../../hooks/useMobileSize";
import Carousel from "../../components/mobile/Carousel/Carousel";
import Slider from "../../components/Slider/Slider";
// import ImageModal from "../../components/modal/ImageModal/ImageModal";
import SelectAddress from "../../components/mobile/SelectAddress/SelectAddress";
import HLSPlayer from "../../components/HslPlayer/HslPlayer";
import BottomSheetV2 from "../../components/mobileV2/BottomSheetV2/BottomSheetV2";
import {LoginModal} from "../../components/mobileV2/LoginModal/useLoginModal";
import ExpandMoreSvg from "../../components/SvgImages/ExpandMoreSvg/ExpandMoreSvg";
import ExpandLessSvg from "../../components/SvgImages/ExpandLessSvg/ExpandLessSvg";
import BuyerProtectionVaultSvg from "../../components/SvgImages/BuyerProtectionVaultSvg/EmailSvg";
import DeliverySvg from "../../components/SvgImages/DeliverySvg/DeliverySvg";
import ReturnArrowSvg from "../../components/SvgImages/ReturnArrowSvg/ReturnArrowSvg";
import StarFilledSvg from "../../components/SvgImages/StarFilledSvg/StarFilledSvg";
import ProductReviewCard from "./Components/ProductReviewCard/ProductReviewCard";
import RatingSvg from "../../components/SvgImages/RatingSvg/RatingSvg";
import ShareSvg from "../../components/SvgImages/ShareSvg/ShareSvg";

const ProductDetails: React.FC = () => {
  const [product, setProduct] = useState<ProductById | null>(null);
  const [selectedVariantIndex, setSelectedVariantIndex] = useState<number>(-1);
  const [variantValues, setVariantValues] = useState<{[key: string]: any}>({});
  const [selectedVariantValue, setSelectedVariantValue] = useState<{
    [key: string]: string | number;
  }>({});
  const [isBuyNowInitiated, setIsBuyNowInitiated] = useState<boolean>(false);
  const [totalReviewCount, setTotalReviewCount] = useState(0);
  const [loadingMoreReviews, setLoadingMoreReviews] = useState<boolean>(false);
  // const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [, setReviewImages] = useState<string[]>([]);
  const [productReviews, setProductReviews] = useState<ReviewProduct[]>([]);
  // const [currentImage, setCurrentImage] = useState<string>("");

  const {storeFront} = useSelector((state: RootState) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const params = useParams<{id: string}>();
  const location = useLocation();
  const [addToCart, setAddToCart] = useState<boolean>(false);
  const {loading} = useSelector((state: RootState) => state.cart);
  // const [visibleReviews, setVisibleReviews] = useState(5);
  const isMobile = useMobileSize();
  const [pauseVideo, setPauseVideo] = useState(false);

  // const INITIAL_REVIEW_COUNT = 5;
  const streamId = location?.state?.stream_id;

  const {isSignedIn} = useSelector((state: RootState) => state.authentication);
  const [loginModal, setLoginModal] = useState(false);
  const [isBuyerProtectionOpen, setIsBuyerProtectionOpen] = useState(false);
  const [isKeyDetailsOpen, setIsKeyDetailsOpen] = useState(false);
  const [isShippingPolicyOpen, setIsShippingPolicyOpen] = useState(false);
  const [isReturnPolicyOpen, setIsReturnPolicyOpen] = useState(false);
  const [isProductDescriptionOpen, setIsProductDescriptionOpen] =
    useState(false);
  useEffect(() => {
    if (cookieUtils.getCookie("token")) {
      dispatch(setAuthentication(true));
    }
  }, []);

  useEffect(() => {
    if (params.id) {
      fetchProduct();
      fetchProductReviews();
    }
  }, [params.id]);

  useEffect(() => {
    if (product) {
      populateVariants();
    }
  }, [product]);

  useEffect(() => {
    const priceAmount = product?.max_selling_price
      ? product?.max_selling_price
      : "null";
    const currency = "INR";
    const availability =
      product?.quantity || 0 > 0 ? "in stock" : "out of stock";

    const priceAmountMeta = document.querySelector(
      'meta[property="og:price:amount"]',
    ) as HTMLMetaElement | null;
    const priceCurrencyMeta = document.querySelector(
      'meta[property="og:price:currency"]',
    ) as HTMLMetaElement | null;
    const productTypeMeta = document.querySelector(
      'meta[property="og:type"]',
    ) as HTMLMetaElement | null;
    const availabilityMeta = document.querySelector(
      'meta[property="og:availability"]',
    ) as HTMLMetaElement | null;

    // Set new content dynamically if the meta tags exist
    if (priceAmountMeta) priceAmountMeta.setAttribute("content", priceAmount);
    if (priceCurrencyMeta) priceCurrencyMeta.setAttribute("content", currency);
    if (productTypeMeta) productTypeMeta.setAttribute("content", "product");
    if (availabilityMeta)
      availabilityMeta.setAttribute("content", availability);

    const jsonLdScript = document.getElementById(
      "json-ld",
    ) as HTMLScriptElement | null;

    if (jsonLdScript) {
      // Replace the placeholders with actual dynamic values
      const jsonLdData = {
        "@context": "https://schema.org",
        "@type": "Product",
        "@id": `/product/${product?.product_name}#product`, // dynamic URL
        name: product?.product_name, // dynamic product name
        description: product?.product_description, // dynamic product description
        "@availability": "http://schema.org/InStock",
      };

      // Ensure we are updating the innerHTML with a JSON string
      jsonLdScript.innerHTML = JSON.stringify(jsonLdData);
    }
  }, [product]);

  useEffect(() => {
    if (
      product &&
      selectedVariantValue &&
      Object.keys(selectedVariantValue).length
    ) {
      onChangeSelectedVariantValue();
    }
  }, [product, selectedVariantValue]);

  const fetchProduct = async () => {
    try {
      const payload = {
        product_id: params.id ?? "",
      };
      const action = await dispatch(getProductThunk(payload));

      if (getProductThunk.fulfilled.match(action)) {
        if (action?.payload?.product) {
          const temp = action.payload.product;
          if (temp) {
            const tempProductPricingDetails: ProductPricingDetail[] = [];

            temp.productPricingDetails?.forEach((variant, index) => {
              tempProductPricingDetails.push({
                ...variant,
                product_price: variant.product_price,
                cost_price: variant.product_price,
                selling_price: variant.selling_price,
                varient_id: variant.id,
              });

              const defaultVariant = temp?.productPricingDetails[0];
              if (
                defaultVariant.variantValueDetails &&
                defaultVariant.variantValueDetails.length > 0
              ) {
                const tempSelectedVariantValue: {
                  [key: string]: string | number;
                } = {};
                defaultVariant.variantValueDetails.map(variantValue => {
                  if (variantValue?.varientCategory?.[0]?.varient_name) {
                    tempSelectedVariantValue[
                      variantValue?.varientCategory?.[0]?.varient_name
                    ] = variantValue.varient_value;
                  }
                });

                setSelectedVariantValue(tempSelectedVariantValue);
                setSelectedVariantIndex(index);
              }
            });

            temp["productPricingDetails"] = tempProductPricingDetails;
            if (temp.product_videos.length > 0) {
              temp["productImageDetails"].splice(1, 0, temp.product_videos[0]);
            }

            setProduct(temp);
          }
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchProductReviews = async (page = 1) => {
    if (loadingMoreReviews) {
      return;
    }
    setLoadingMoreReviews(true);
    const payload: GetProductReviewsPayload = {
      product_id: params.id ?? "",
      type: "product",
      limit: 6,
      page,
    };
    try {
      const action = await dispatch(getProductReviewsThunk(payload));
      if (getProductReviewsThunk.fulfilled.match(action)) {
        const reviewDetails = action.payload.review_details;
        setTotalReviewCount(action.payload.count);
        setProductReviews([...reviewDetails.reviews]);
        const allReviewImages = reviewDetails.reviews.flatMap(
          review => review.media_urls || [],
        );
        setReviewImages([...allReviewImages]);
      } else {
        console.log("Error fetching reviews");
      }
    } catch (error) {
      console.log("error fetching reviews", error);
    } finally {
      setLoadingMoreReviews(false);
    }
  };

  const populateVariants = () => {
    const tempVariants: {[key: string]: any} = {};
    product?.productPricingDetails?.forEach(variant =>
      variant.variantValueDetails?.forEach(variantValue => {
        if (variantValue?.varientCategory?.[0]?.varient_name) {
          if (tempVariants[variantValue?.varientCategory?.[0]?.varient_name]) {
            const ob = findByKey(
              tempVariants[variantValue?.varientCategory?.[0]?.varient_name],
              "variant_value_id",
              variantValue.id,
            );

            if (!ob) {
              tempVariants[
                variantValue?.varientCategory?.[0]?.varient_name
              ].push({
                name: variantValue.varient_name,
                value: variantValue.varient_value,
                variant_value_id: variantValue.id,
              });
            }
          } else {
            tempVariants[variantValue?.varientCategory?.[0]?.varient_name] = [
              {
                name: variantValue.varient_name,
                value: variantValue.varient_value,
                variant_value_id: variantValue.id,
              },
            ];
          }
        }
      }),
    );

    setVariantValues(tempVariants);
  };

  const onChangeSelectedVariantValue = () => {
    const findVariantIndex = (data: any, selectedVariantValue: any) => {
      return data.findIndex((item: any) => {
        // Check if all variant values match
        return item.variantValueDetails.every((variant: any) => {
          const categoryName = variant.varientCategory[0].varient_name;
          const value = variant.varient_value;

          // Match against the selected values
          return selectedVariantValue[categoryName] === value;
        });
      });
    };

    const index = findVariantIndex(
      product?.productPricingDetails,
      selectedVariantValue,
    );

    setSelectedVariantIndex(index);
  };

  const addProductTocart = async () => {
    const payload: AddProductToCartPayload = {
      product_id: product?.id.toString() ?? "",
      product_pricing_details_id:
        product?.["productPricingDetails"]?.[
          selectedVariantIndex
        ].varient_id?.toString() ?? "",
      quantity: "1",
    };

    try {
      const action = await dispatch(addProductToCartThunk(payload));
      if (addProductToCartThunk.fulfilled.match(action)) {
        setAddToCart(true);
        // setLoading(false)
      } else {
        setAddToCart(false);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  // const handleOpenModal = (imageSrc: string) => {
  //   setCurrentImage(imageSrc);
  //   setIsModalOpen(true);
  // };

  // const handleSeeMoreReviews = () => {
  //   setVisibleReviews(prevVisible => prevVisible + 5);
  // };

  // const handleSeeLessReviews = () => {
  //   setVisibleReviews(INITIAL_REVIEW_COUNT);
  // };

  if (!product) {
    return <Loader />;
  }

  const handleVideoPause = () => {
    setPauseVideo(!pauseVideo);
  };

  const hlsContainerStyle: React.CSSProperties = {
    marginTop: 0,
    padding: 0,
    width: "300px",
    height: "450px",
  };

  const hlsContainerStyleDesktop: React.CSSProperties = {
    marginTop: 0,
    padding: 0,
    width: "354px",
    height: "502px",
  };

  return (
    <div className="product-details">
      {isMobile ? (
        <Carousel jumpToIndex={0} showIndicator={true}>
          {product?.productImageDetails?.map((image, index) => (
            <div key={index} className="product-img-container">
              <div key={index}>
                {index === 1 && product?.product_videos?.length > 0 ? (
                  <div className="productImage" onClick={handleVideoPause}>
                    <HLSPlayer
                      data={{
                        public_url: product?.product_videos[0],
                      }}
                      isMuted={true}
                      hlsVideoStyle={hlsContainerStyle}
                      pauseVideo={pauseVideo}
                    />
                  </div>
                ) : (
                  <img
                    alt="Thumbnail"
                    className="productImage"
                    src={image ?? "/avatar.png"}
                  />
                )}
                {product?.productImageDetails?.length > 1 && (
                  <div className="product-img-pagination-container">
                    <div className="product-img-pagination-container-text">
                      {index + 1}/{product?.productImageDetails?.length}
                    </div>
                  </div>
                )}
                {product?.product_rating && (
                  <div className="product-img-container-rating">
                    <div className="product-img-container-rating-text">
                      <RatingSvg height={16} width={16} />
                      {product?.product_rating}
                    </div>
                  </div>
                )}
                <div className="product-img-container-right-container">
                  <div
                    className="product-img-container-right-container-share-button"
                    onClick={() => {
                      const url = window.location.href; // Detect the current hosted URL
                      if (navigator.share) {
                        navigator
                          .share({
                            title: product?.product_name ?? "Check out!!!",
                            text: `Check out ${product?.product_name ?? ""} on Zoop :`,
                            url: url, // Use the detected URL
                          })
                          .then(() => console.log("Shared successfully!"))
                          .catch(error =>
                            console.error("Error sharing:", error),
                          );
                      } else {
                        alert(
                          "Your browser does not support the Web Share API.",
                        );
                      }
                    }}>
                    <ShareSvg
                      height={20}
                      width={20}
                      color="var(--content-inverted)"
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      ) : (
        <div
          className={
            product?.productImageDetails.length == 1
              ? "product-details-review-single-images"
              : "product-details-review-images"
          }>
          {product?.productImageDetails?.map((item, index) => (
            <>
              {index === 1 && product?.product_videos?.length > 0 ? (
                <div
                  className="product-details-product-image"
                  onClick={handleVideoPause}>
                  <HLSPlayer
                    data={{
                      public_url: product?.product_videos[0],
                    }}
                    isMuted={true}
                    hlsVideoStyle={hlsContainerStyleDesktop}
                    hlsContainerStyle={{
                      border: "none",
                      marginTop: "0",
                      padding: "0",
                    }}
                    pauseVideo={pauseVideo}
                  />
                </div>
              ) : (
                <img
                  src={item}
                  className="product-details-product-image"
                  key={index}
                />
              )}
            </>
          ))}
        </div>
      )}

      <div className="product-details-content-container">
        <div className="product-header-container">
          <div className="product-header-left">
            <div>
              {parseInt(
                product?.productPricingDetails?.[selectedVariantIndex]?.[
                  "product_price"
                ],
              ) -
                parseInt(
                  product?.productPricingDetails?.[selectedVariantIndex]?.[
                    "selling_price"
                  ],
                ) !=
              0 ? (
                <div className="product-details-discount-percent">
                  {parseInt(
                    Math.ceil(
                      (100 *
                        (parseInt(
                          product?.productPricingDetails?.[
                            selectedVariantIndex
                          ]?.["product_price"]?.toString(),
                        ) -
                          parseInt(
                            product?.productPricingDetails?.[
                              selectedVariantIndex
                            ]?.["selling_price"]?.toString(),
                          ))) /
                        parseInt(
                          product?.productPricingDetails?.[
                            selectedVariantIndex
                          ]?.["product_price"]?.toString(),
                        ),
                    ).toString(),
                  )}
                  {`% off`}
                </div>
              ) : (
                <div />
              )}
            </div>
            <div className="product-price-container">
              {selectedVariantValue &&
              Object.keys(selectedVariantValue).length &&
              selectedVariantIndex != -1 ? (
                <React.Fragment>
                  <div className="product-details-price">
                    <div className="product-details-selling-price">
                      ₹
                      {convertIntoINR(
                        product?.productPricingDetails?.[
                          selectedVariantIndex
                        ]?.["selling_price"],
                      )}
                    </div>
                    {parseInt(
                      product?.productPricingDetails?.[selectedVariantIndex]?.[
                        "product_price"
                      ],
                    ) -
                      parseInt(
                        product?.productPricingDetails?.[
                          selectedVariantIndex
                        ]?.["selling_price"],
                      ) !=
                    0 ? (
                      <div className="product-details-discount-price">
                        ₹
                        {convertIntoINR(
                          product?.productPricingDetails?.[
                            selectedVariantIndex
                          ]?.["product_price"],
                        )}
                      </div>
                    ) : (
                      <div />
                    )}
                  </div>
                </React.Fragment>
              ) : (
                <span>Select Variant</span>
              )}
            </div>
            <div className="product-header-title">{product?.product_name}</div>
            <div className="product-header-rediv">
              {product?.product_description}
            </div>
            <div className="product-header-store-name">
              by{" "}
              <span className="product-header-store-name-text">
                {storeFront?.details?.store_name}
              </span>
            </div>
          </div>
        </div>

        {variantValues &&
          Object.keys(variantValues).length > 0 &&
          !variantValues?.["No Variant"] && (
            <div className="product-details-size-box">
              {Object.entries(variantValues).map(
                ([variantCategory, variantCategoryDetail], index) => {
                  return (
                    <div key={index} style={{width: "100%"}}>
                      <div className="product-details-title-container">
                        <span className="product-details-variant-label">
                          {variantCategory}
                        </span>
                      </div>
                      <div className="product-details-size-selector">
                        <div className="product-details-size-selector-list">
                          {variantCategory === "Colour" ? (
                            <Slider
                              options={variantCategoryDetail?.map(
                                (variantCategoryValue: any) => {
                                  return {
                                    id: variantCategoryValue.name,
                                    value:
                                      colourCode?.[variantCategoryValue.name] ??
                                      variantCategoryValue.name,
                                  };
                                },
                              )}
                              renderOption={(option, isSelected) => (
                                <div
                                  className="color-option"
                                  style={{
                                    backgroundColor: option.value,
                                    border: isSelected
                                      ? "1px solid var(--border-action)"
                                      : "1px solid var(--border-primary)",
                                  }}
                                />
                              )}
                              onSelect={(selectedOption: any) => {
                                setSelectedVariantValue({
                                  ...selectedVariantValue,
                                  [variantCategory]: selectedOption.id,
                                });
                              }}
                            />
                          ) : (
                            <React.Fragment>
                              <Slider
                                options={variantCategoryDetail.map(
                                  (variantCategoryValue: any) => {
                                    return {
                                      id: variantCategoryValue.name,
                                      value: variantCategoryValue.name,
                                    };
                                  },
                                )}
                                renderOption={(
                                  option: any,
                                  isSelected: boolean,
                                ) => (
                                  <div
                                    className="size-option"
                                    style={{
                                      borderColor: isSelected
                                        ? "var(--border-action)"
                                        : "var(--border-primary)",
                                    }}>
                                    {option.value}
                                  </div>
                                )}
                                onSelect={selectedOption => {
                                  console.log(
                                    "selected variable :::",
                                    selectedOption,
                                  );
                                  setSelectedVariantValue({
                                    ...selectedVariantValue,
                                    [variantCategory]: selectedOption.id,
                                  });
                                }}
                              />
                            </React.Fragment>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                },
              )}
            </div>
          )}

        <div className="product-detail-action-cta-container">
          {!location?.state?.hide_buy_now && (
            <>
              <button
                className="product-detail-buy-now"
                disabled={selectedVariantIndex == -1}
                onClick={() => {
                  if (isSignedIn) {
                    if (streamId) {
                      navigate(-1);
                    } else {
                      setIsBuyNowInitiated(true);
                    }
                  } else {
                    setLoginModal(true);
                  }
                }}>
                <span className="product-detail-text">
                  {streamId ? "Back to Stream" : "Buy Now"}
                </span>
              </button>

              <div className="product-detail-action-cta-container-bottom">
                <button
                  className="product-detail-add-cart"
                  disabled={selectedVariantIndex == -1}
                  onClick={() => {
                    if (isSignedIn) {
                      if (addToCart) {
                        navigate("/checkout");
                      } else {
                        addProductTocart();
                      }
                    } else {
                      setLoginModal(true);
                    }
                  }}>
                  <span className="product-detail-text">
                    {loading ? <Loader /> : ""}
                    {addToCart ? "Go to Cart" : "Add to Cart"}
                  </span>
                </button>

                <button className="product-detail-bargain">
                  <span className="product-detail-bargain-text">Bargain</span>
                </button>
              </div>
            </>
          )}
        </div>

        <div className="product-details-buyer-protection-container">
          <div
            className="product-details-buyer-protection-container-button"
            onClick={() => setIsBuyerProtectionOpen(!isBuyerProtectionOpen)}>
            <div className="product-details-buyer-protection-left">
              <div className="product-details-buyer-protection-left-icon">
                <BuyerProtectionVaultSvg height={24} width={24} />
              </div>
              <div className="product-details-buyer-protection-left-title">
                Buyer Protection
              </div>
            </div>
            <div className="product-details-buyer-protection-right">
              {!isBuyerProtectionOpen ? (
                <ExpandMoreSvg height={"20"} width={"20"} />
              ) : (
                <ExpandLessSvg height={"20"} width={"20"} />
              )}
            </div>
          </div>
          {isBuyerProtectionOpen && (
            <div className="product-details-drop-down-container-content">
              <span>
                Receive your item as described, or get your money back.{" "}
              </span>

              <span
                className="product-details-buyer-protection-container-content-learn-more"
                onClick={() => {
                  window.open(
                    "https://seller.zooplive.com/buyerprotectionpolicy/",
                    "_blank",
                  );
                }}>
                Learn more
              </span>
            </div>
          )}
        </div>
        <div className="product-details-product-details-container">
          <div className="product-details-product-details-container-title">
            Product Information
          </div>
          {product?.meta_data && (
            <div className="product-details-product-details-container-dropedown">
              <div
                className="product-details-product-details-container-dropedown-header"
                onClick={() => setIsKeyDetailsOpen(!isKeyDetailsOpen)}>
                <div className="product-details-product-details-container-dropedown-header-title">
                  Key details
                </div>
                <div className="product-details-product-details-container-dropedown-header-expand-svg">
                  {!isKeyDetailsOpen ? (
                    <ExpandMoreSvg height={"20"} width={"20"} />
                  ) : (
                    <ExpandLessSvg height={"20"} width={"20"} />
                  )}
                </div>
              </div>
              {isKeyDetailsOpen && (
                <div className="product-details-drop-down-container-content-key-details">
                  {Object.entries(product?.meta_data).map(
                    ([key, value], index) => (
                      <span
                        className="product-details-drop-down-container-content-key-details-item"
                        key={index}>
                        <span className="product-details-drop-down-container-content-key-details-key">
                          {key.charAt(0).toUpperCase() + key.slice(1)}:
                        </span>
                        <span className="product-details-drop-down-container-content-key-details-value">
                          {value}
                        </span>
                      </span>
                    ),
                  )}
                </div>
              )}
            </div>
          )}
          <div className="product-details-product-details-container-dropedown">
            <div
              className="product-details-product-details-container-dropedown-header"
              onClick={() =>
                setIsProductDescriptionOpen(!isProductDescriptionOpen)
              }>
              <div className="product-details-product-details-container-dropedown-header-title">
                Product Description
              </div>
              <div className="product-details-product-details-container-dropedown-header-expand-svg">
                {!isProductDescriptionOpen ? (
                  <ExpandMoreSvg height={"20"} width={"20"} />
                ) : (
                  <ExpandLessSvg height={"20"} width={"20"} />
                )}
              </div>
            </div>
          </div>
          {isProductDescriptionOpen && (
            <div className="product-details-drop-down-container-content-product">
              <span>{product?.product_description}</span>
            </div>
          )}
        </div>
        <div className="product-details-seller-details-container">
          <div className="product-details-seller-details-container-title">
            Seller Details
          </div>
          <div className="product-details-seller-details-container-content">
            <div
              className="product-details-seller-details-container-content-left"
              onClick={() => {
                navigate(`/storefront/${storeFront.details?.store_id}`);
              }}>
              <img
                src={
                  storeFront?.details?.profile_url ||
                  "https://storage.googleapis.com/zoop-public-bucket/ZOOP-RECOMMENDS/productstock.png"
                }
                alt="Seller Avatar"
                className="product-details-seller-details-container-content-left-avatar"
              />
              <div className="product-details-seller-details-container-content-left-name-ctn">
                <span className="product-details-seller-details-container-content-left-name">
                  {storeFront?.details?.store_name}
                </span>
                <span className="product-details-seller-details-container-content-left-name-text">
                  View store products
                </span>
              </div>
            </div>
            <div className="product-details-seller-details-container-content-right">
              <button className="product-details-seller-details-container-content-right-button">
                Message
              </button>
            </div>
          </div>
        </div>
        <div className="product-details-review-container">
          <div className="product-details-review-container-title">Reviews</div>
          {product?.product_rating ? (
            <div
              className="product-details-review-rating-card"
              // onClick={() => setReviewOverlayOpen(true)}
            >
              <div className="product-details-review-rating-card-heading">
                <div className="product-details-review-rating-card-heading-rating">
                  <div className="product-details-review-rating-card-heading-rating-star">
                    <StarFilledSvg height={"20"} width={"20"} />
                  </div>
                  <div className="product-details-review-rating-card-heading-rating-text">
                    {product?.product_rating || "N/A"}
                  </div>
                </div>
                <div className="product-details-review-rating-card-heading-rating-separator">
                  •
                </div>
                <div className="product-details-review-rating-card-heading-summary">
                  {totalReviewCount || "N/A"} Reviews
                </div>
              </div>
              <div
                className="product-details-reviews-card-container"
                onClick={() => {
                  navigate(`/product/${params.id}/reviews`);
                }}>
                {productReviews.map((review, key) => (
                  <ProductReviewCard review={review} key={key} />
                  // <></>
                ))}
              </div>
              <div className="product-details-review-show-all-reviews-button-container">
                <button
                  className={`product-details-review-show-all-reviews-button`}
                  onClick={() => {
                    navigate(`/product/${params.id}/reviews`);
                  }}>
                  {/* {<Loader size={12}/> : "Unfollow"} */}
                  Show all reviews
                </button>
              </div>
            </div>
          ) : (
            <span className="product-details-review-no-reviews-text">
              This item and store have not been reviewed yet. Buy it now and
              leave a review :D
            </span>
          )}
        </div>

        {/*         
        <div className="product-details-review-container">
        <div className="storefront-about-section-rating-card-heading">
          <div className="storefront-about-section-rating-card-heading-rating">
            <div className="storefront-about-section-rating-card-heading-rating-star">
              <StarFilledSvg height={"20"} width={"20"} />
            </div>
            <div className="storefront-about-section-rating-card-heading-rating-text">
              {storeFront.about?.store_rating || "N/A"}
            </div>
          </div>
          <div className="storefront-about-section-rating-card-heading-rating-separator">
            •
          </div>
          <div className="storefront-about-section-rating-summary">
            {reviewCount || "N/A"} Reviews
          </div>
        </div>


        </div> */}

        <div className="product-details-policy-container">
          <div className="product-details-policy-container-item-container">
            <div
              className="product-details-policy-container-item"
              onClick={() => setIsShippingPolicyOpen(!isShippingPolicyOpen)}>
              <div className="product-details-policy-container-item-left">
                <div className="product-details-policy-container-item-left-icon">
                  <DeliverySvg height={20} width={20} />
                </div>
                <div className="product-details-policy-container-item-left-title">
                  Shipping Policy
                </div>
              </div>
              <div className="product-details-policy-container-item-right">
                {!isShippingPolicyOpen ? (
                  <ExpandMoreSvg height={"20"} width={"20"} />
                ) : (
                  <ExpandLessSvg height={"20"} width={"20"} />
                )}
              </div>
            </div>
            {isShippingPolicyOpen && (
              <span className="product-details-drop-down-container-content-product">
                The seller is responsible for the shipment of the sold items to
                the customer. We urge all sellers to ship their products within
                5-7 days of receiving an order. Once the order is picked up from
                the seller, we rely on our courier partners to deliver the order
                at the earliest to the customer. The order is usually delivered
                between 3-5 days post pickup from the seller. We are not
                responsible for any delays caused by our courier partners. You
                can read their terms here . We are not liable for any damages
                (including, without limitation, any incidental or consequential
                damages) arising from the failure of a seller or any shipping
                carrier, or a contractor engaged by the seller or shipping
                carrier to deliver.
              </span>
            )}
          </div>
          <div className="product-details-policy-container-item-container">
            <div
              className="product-details-policy-container-item"
              onClick={() => setIsReturnPolicyOpen(!isReturnPolicyOpen)}>
              <div className="product-details-policy-container-item-left">
                <div className="product-details-policy-container-item-left-icon">
                  <ReturnArrowSvg height={20} width={20} />
                </div>
                <div className="product-details-policy-container-item-left-title">
                  Return/Exchange Policy
                </div>
              </div>
              <div className="product-details-policy-container-item-right">
                {!isReturnPolicyOpen ? (
                  <ExpandMoreSvg height={"20"} width={"20"} />
                ) : (
                  <ExpandLessSvg height={"20"} width={"20"} />
                )}
              </div>
            </div>
            {isReturnPolicyOpen && (
              <div className="storefront-about-section-store-info-card-body-content">
                <span className="storefront-about-section-return-policy-description">
                  Acceptable reasons to claim an exchange
                </span>
                <ul className="storefront-about-section-return-policy-list">
                  <li>A defective product</li>
                  <li>Missing item / piece</li>
                  <li>No returns / refunds only exchange!</li>
                </ul>
                <span className="storefront-about-section-return-policy-description">
                  No cancellations once order is accepted and processed. Transit
                  delays are not in our hands; however, we will help you to get
                  the order delivered soon!
                </span>
              </div>
            )}
          </div>
        </div>

        {/* <div className="product-details-accordion-container">
          <div className="product-details-accordion-container-item">
            <Accordion
              accordionData={[
                ...(product?.meta_data
                  ? [
                      {
                        title: "Product Details",
                        content: (
                          <div>
                            {Object.entries(product.meta_data)
                              .filter(
                                ([, value]) =>
                                  value !== null && value !== undefined,
                              )
                              .map(([key, value]) => (
                                <p
                                  key={key}
                                  className="product-details-meta-data-container">
                                  <span className="product-details-meta-data-key">
                                    {key
                                      .replace(/_/g, " ")
                                      .replace(/\b\w/g, l => l.toUpperCase())}
                                    :{" "}
                                  </span>
                                  <span className="product-details-meta-data-value">
                                    {value}
                                  </span>
                                </p>
                              ))}
                          </div>
                        ),
                      },
                    ]
                  : []),

                {
                  title: "Product Description",
                  content: (
                    <p>
                      {" "}
                      {product?.product_description
                        .split("\n")
                        .map((line, index) => {
                          // Check if the line is empty and if the previous line was also empty, indicating two consecutive newlines
                          const isIndented = line === "";
                          return (
                            <p
                              key={index}
                              style={{marginBottom: isIndented ? "20px" : "0"}}>
                              {line}
                            </p>
                          );
                        })}
                    </p>
                  ),
                },
                {
                  title: "Seller Details",
                  content: (
                    <div className="product-details-store-info">
                      {storeFront?.details?.store_id && (
                        <button
                          className="product-detail"
                          onClick={() => {
                            navigate(
                              `/storefront/${storeFront.details?.store_id}`,
                            );
                            // const hostname = window.location.hostname;
                            // const subdomain = hostname.split(".")[0];
                            // console.log("url :::", subdomain)
                            // window.open(`${storeFront.details?.store_name}.${url}`, '_blank');
                          }}>
                          <span className="product-detail-text">
                            Visit Storefront
                          </span>
                        </button>
                      )}
                    </div>
                  ),
                },

                {
                  title: "Shipping Policy",
                  content: (
                    <p className="product-details-return-policy">
                      The seller is responsible for the shipment of the sold
                      items to the customer. We urge all sellers to ship their
                      products within 5-7 days of receiving an order. Once the
                      order is picked up from the seller, we rely on our courier
                      partners to deliver the order at the earliest to the
                      customer. The order is usually delivered between 3-5 days
                      post pickup from the seller. We are not responsible for
                      any delays caused by our courier partners. You can read
                      their terms{" "}
                      <Link
                        to="https://checkout.shiprocket.in/terms-conditions/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="product-details-link">
                        here
                      </Link>{" "}
                      . We are not liable for any damages (including, without
                      limitation, any incidental or consequential damages)
                      arising from the failure of a seller or any shipping
                      carrier, or a contractor engaged by the seller or shipping
                      carrier to deliver.
                    </p>
                  ),
                },
                {
                  title: "Return/Exchange Policy",
                  content: (
                    <p className="product-details-return-policy">
                      We are committed to ensuring a trustworthy buying
                      experience for everyone. So in those rare circumstances
                      where you have an issue with your order, we have got your
                      back. However, considering the nature of listings from our
                      sellers, we will be able to offer exchange, returns or
                      refund only in rare scenarios. Our &apos;Buyer Protection
                      Policy&apos; goes into more detail below on when buyers
                      are entitled to a refund and how to get a refund. We urge
                      you to carefully read the same available on our{" "}
                      <Link
                        to="https://seller.zooplive.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="product-details-link">
                        website{" "}
                      </Link>
                      . In case of any dispute, we collect information and
                      evidence from both the buyer and seller. A balanced
                      decision is then taken in light of the facts, our
                      guidelines and policies. For any queries or grievances,
                      write to us:{" "}
                      <Link
                        to="mailto:help@zooplive.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="product-details-link">
                        help@zooplive.com
                      </Link>{" "}
                    </p>
                  ),
                },
              ]}
            />
          </div>
        </div> */}

        {/* {(reviewImages?.length > 0 || productReviews.length > 0) && (
          <div className="product-detials-ratings-reviews-container">
            <div className="product-details-title-container">
              <div className="line"></div>
              <div className="details-text">Reviews</div>
              <div className="line"></div>
            </div>

            <div className="product-details-overall-rating">
              <IoStarSharp size={24} color="#1F2937" />
              <div className="product-details-star-count">
                <span> 4.2</span>
                <span className="product-details-total-reviews">
                  {`(${totalReviewCount})`}
                </span>
              </div>
            </div>

            <div className="product-details-review-images">
              {reviewImages.map((item, index) => (
                <button
                  key={index}
                  className="product-details-review-img-btn"
                  onClick={() => handleOpenModal(item)}>
                  <img src={item} className="product-details-review-image" />
                </button>
              ))}
            </div>
            <div className="product-details-reviews-section">
              {productReviews.slice(0, visibleReviews).map((item, index) => (
                <ReviewCard
                  key={index}
                  name={item.name}
                  date={item.created_at}
                  image={item.profile_photo}
                  description={item.review_text}
                />
              ))}
            </div>

            <div className="product-details-see-all-review">
              {visibleReviews < productReviews.length && (
                <button onClick={handleSeeMoreReviews}>See all reviews</button>
              )}
              {visibleReviews == productReviews.length && (
                <button onClick={handleSeeLessReviews}>See less reviews</button>
              )}
            </div>
          </div>
        )} */}
        {/* <ImageModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          imageSrc={currentImage}
        /> */}
        <SelectAddress
          {...(streamId ? {streamId: streamId} : {})}
          storeId={product.store_id}
          open={isBuyNowInitiated && product ? true : false}
          onClose={() => {
            setIsBuyNowInitiated(false);
          }}
          product={
            selectedVariantIndex != -1 &&
            product?.["productPricingDetails"]?.[selectedVariantIndex]
              ? product?.["productPricingDetails"]?.[selectedVariantIndex]
              : {}
          }
        />
      </div>
      <BottomSheetV2
        isOpen={loginModal}
        onClose={() => {
          setLoginModal(false);
        }}>
        <LoginModal
          onSuccess={() => {
            setLoginModal(false);
          }}
        />
      </BottomSheetV2>
    </div>
  );
};

export default ProductDetails;
