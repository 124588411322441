import React, {useState, ChangeEvent} from "react";
import ReactGA from "react-ga4";
import "./LoginPhone.style.css";
import {Link, useLocation} from "react-router-dom";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../../interceptors/store";
import {getSendOtpAuthenticationThunk} from "../../../../features/Authentication/AuthenticationSlice";
import {toast} from "react-toastify";
import Loader from "../../../../components/CustomLoader/CustomLoader";

interface LoginPhoneProps {
  mobileNumber: string;
  setIsOtpSent: (isOtpSent: boolean) => void;
  setMobileNumber: (mobileNumber: string) => void;
  setMessageId: (messageId: string) => void;
}

export function LoginPhone({
  mobileNumber,
  setIsOtpSent,
  setMobileNumber,
  setMessageId,
}: LoginPhoneProps) {
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();

  const handleMobileNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    if (input.length === 10) {
      ReactGA.event({
        category: "User",
        action: "Mobile Input",
        label: "User Mobile Input",
        value: 1,
        nonInteraction: true,
        // @ts-expect-error: Ignoring the type check for custom dimensions
        dimension1: location.pathname + location.search,
        dimension2: localStorage.getItem("userId") || null,
        dimension3: input,
      });
    }
    if (input.length <= 10) {
      setMobileNumber(input);
    }
  };
  // const showErrorEvent = (data: string) => {
  //     ReactGA.event({
  //       category: "Error",
  //       action: "SendOtpError",
  //       label: "Send OTP Error",
  //       value: 1,
  //       nonInteraction: true,
  //       // @ts-expect-error: Ignoring the type check for custom dimensions
  //       dimension1: location.pathname + location.search,
  //       dimension2: localStorage.getItem("userId") || null,
  //       dimension3: "+91",
  //       dimension4: "buyer",
  //       dimension5: mobileNumber,
  //       dimension6: JSON.stringify(data),
  //     });
  //   };

  //   const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
  //     e.preventDefault();
  //     setLoginScreen(loginScreen + 1);
  //   };

  // const handleNext = () => {
  //   console.log("Next");
  //   setLoginScreen(loginScreen + 1);
  // };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleNextClick = async () => {
    if (mobileNumber.length === 10) {
      setLoading(true);
      try {
        ReactGA.event({
          category: "User",
          action: "SendOtp",
          label: "Send OTP",
          value: 1,
          nonInteraction: true,
          // @ts-expect-error: Ignoring the type check for custom dimensions
          dimension1: location.pathname + location.search,
          dimension2: localStorage.getItem("userId") || null,
          dimension3: "+91",
          dimension4: "buyer",
          dimension5: mobileNumber,
        });

        const action = await dispatch(
          getSendOtpAuthenticationThunk({
            country_code: "+91",
            user_type: "buyer",
            mobile: mobileNumber,
          }),
        );

        if (getSendOtpAuthenticationThunk.fulfilled.match(action)) {
          if (action && action?.payload.success) {
            if (action?.payload?.data?.message_id) {
              toast.success("OTP sent successfully", {
                toastId: action?.payload?.data?.message_id,
              });
              setMessageId(action?.payload?.data?.message_id);
              setIsOtpSent(true);
              setLoading(false);
            } else {
              toast.error("Error sending OTP");
            }
          }
        } else {
          toast.error("Error sending OTP");
        }
      } catch {
        toast.error("An error occurred. Please try again.");
      }
    } else {
      toast.error("Please enter a valid 10-digit mobile number.");
    }
    setLoading(false);
  };

  const preventWheel = (event: React.WheelEvent<HTMLInputElement>) => {
    // Blur the input element that triggered the event
    (event.target as HTMLInputElement).blur();
  };

  return (
    <>
      <div className="login-phone-container">
        <div className="login-phone-header">
          <span className="login-phone-header-text">
            Login or Create an account
          </span>
        </div>
        <div className="login-phone-input-number">
          <span className="login-phone-input-number-text">Mobile number</span>
          <div className="login-phone-input-number-container">
            <span className="login-phone-input-number-container-text">+91</span>
            <input
              className="login-phone-input-number-input"
              value={mobileNumber}
              onChange={handleMobileNumberChange}
              type="number"
              onWheel={preventWheel}
              placeholder="10 digit mobile number"
            />
          </div>
        </div>
        <div className="login-phone-policy-container">
          <input
            type="checkbox"
            className="login-phone-policy-container-checkbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
          <span className="login-phone-policy-container-text">
            I agree to the{" "}
            <Link
              className="login-phone-policy-container-text-link"
              to="https://storage.googleapis.com/zoop-public-bucket/Terms%20of%20Use-Buyer%20App%2016July.pdf"
              target="_blank"
              rel="noopener noreferrer">
              terms and conditions
            </Link>
            . By checking this box, I acknowledge that I have read, understood,
            and accept all terms outlined in the agreement and the{" "}
            <Link
              className="login-phone-policy-container-text-link"
              to="https://seller.zooplive.com/privacy-policy/"
              rel="noopener noreferrer"
              target="_blank">
              privacy policy
            </Link>
            .
          </span>
        </div>
        <div className="login-phone-button-container">
          <button
            className="login-phone-button"
            onClick={handleNextClick}
            disabled={mobileNumber.length !== 10 || !isChecked}>
            {loading ? <Loader size={14} /> : "Get OTP"}
          </button>
        </div>
      </div>
    </>
  );
}
