import React, {useState, useRef} from "react";
import "./LoginPhoneOTP.style.css";
import {toast} from "react-toastify";
import {
  getSendOtpAuthenticationThunk,
  getVerifyOtpAuthenticationThunk,
} from "../../../../features/Authentication/AuthenticationSlice";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../../interceptors/store";
import {useLocation} from "react-router-dom";
import Loader from "../../../../components/CustomLoader/CustomLoader";
import ReactGA from "react-ga4";
import useTimer from "../../../../hooks/useTime";

interface LoginPhoneOTPProps {
  mobileNumber: string;
  setMessageId: (messageId: string) => void;
  messageId: string;
  setIsOtpVerified: (isOtpVerified: boolean) => void;
}

export function LoginPhoneOTP({
  mobileNumber,
  messageId,
  setIsOtpVerified,
  setMessageId,
}: LoginPhoneOTPProps) {
  const [otp, setOtp] = useState<string>("");
  const otpInputs = useRef<HTMLInputElement[]>([]);
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState<boolean>(false);

  const location = useLocation();

  const {time, resetTimer} = useTimer({
    initialTime: 30,
    interval: 1000,
    onFinish: () => {
      console.log("time Finished");
    },
  });

  const handleResendOtp = async () => {
    setLoading(true);
    ReactGA.event({
      category: "User",
      action: "ResendOtp",
      label: "Resend OTP",
      value: 1,
      nonInteraction: true,
      // @ts-expect-error: Ignoring the type check for custom dimensions
      dimension1: location.pathname + location.search,
      dimension2: localStorage.getItem("userId") || null,
      dimension3: mobileNumber,
      dimension4: "+91",
      dimension5: "buyer",
    });

    const action = await dispatch(
      getSendOtpAuthenticationThunk({
        country_code: "+91",
        user_type: "buyer",
        mobile: mobileNumber,
      }),
    );

    if (getSendOtpAuthenticationThunk.fulfilled.match(action)) {
      if (action?.payload?.data?.message_id) {
        setMessageId(action?.payload?.data?.message_id);
        toast.success("OTP resent successfully");
        resetTimer(30);
        setLoading(false);
      } else {
        ReactGA.event({
          category: "User",
          action: "ResendOtpError",
          label: "Resend OTP Error",
          value: 1,
          nonInteraction: true,
          // @ts-expect-error: Ignoring the type check for custom dimensions
          dimension1: location.pathname + location.search,
          dimension2: localStorage.getItem("userId") || null,
          dimension3: mobileNumber,
          dimension4: "+91",
          dimension5: "buyer",
        });
        toast.error("Error resending OTP");
      }
    } else {
      ReactGA.event({
        category: "User",
        action: "ResendOtpError",
        label: "Resend OTP Error",
        value: 1,
        nonInteraction: true,
        // @ts-expect-error: Ignoring the type check for custom dimensions
        dimension1: location.pathname + location.search,
        dimension2: localStorage.getItem("userId") || null,
        dimension3: mobileNumber,
        dimension4: "+91",
        dimension5: "buyer",
      });
      toast.error("Error resending OTP");
    }
  };

  const handleOtpChange = (index: number, value: string) => {
    const digits = value.replace(/\D/g, "").split("");

    if (digits.length > 0) {
      const newOtp = [...otp];
      let currentIndex = index;

      digits.forEach(digit => {
        if (currentIndex < 6) {
          newOtp[currentIndex] = digit;
          otpInputs.current[currentIndex].value = digit;
          currentIndex++;
        }
      });

      setOtp(newOtp.join(""));

      if (currentIndex < 6) {
        otpInputs.current[currentIndex].focus();
      }
    }
  };
  const handleVerifyOtp = async () => {
    if (otp.length === 6) {
      setLoading(true);
      try {
        ReactGA.event({
          category: "User",
          action: "VerifyOtp",
          label: "Verify OTP Attempt",
          value: 1,
          nonInteraction: true,
          // @ts-expect-error: Ignoring the type check for custom dimensions
          dimension1: location.pathname + location.search,
          dimension2: localStorage.getItem("userId") || null,
          dimension3: mobileNumber,
          dimension4: "+91",
          dimension5: "buyer",
          dimension6: otp,
        });

        const action = await dispatch(
          getVerifyOtpAuthenticationThunk({
            country_code: "+91",
            user_type: "buyer",
            mobile: mobileNumber,
            otp: Number(otp),
            message_id: messageId,
          }),
        );

        if (getVerifyOtpAuthenticationThunk.fulfilled.match(action)) {
          toast.success("OTP verified successfully", {
            toastId: messageId,
          });
          // const data = action.payload;
          setLoading(false);
          setIsOtpVerified(true);
        } else {
          ReactGA.event({
            category: "User",
            action: "VerifyOtpError",
            label: "Verify OTP Error",
            value: 1,
            nonInteraction: true,
            // @ts-expect-error: Ignoring the type check for custom dimensions
            dimension1: location.pathname + location.search,
            dimension2: localStorage.getItem("userId") || null,
            dimension3: mobileNumber,
            dimension4: "+91",
            dimension5: "buyer",
            dimension6: otp,
          });
          toast.error("Error verifying OTP");
          setLoading(false);
        }
      } catch {
        ReactGA.event({
          category: "User",
          action: "InvalidOtpError",
          label: "Invalid OTP Error",
          value: 1,
          nonInteraction: true,
          // @ts-expect-error: Ignoring the type check for custom dimensions
          dimension1: location.pathname + location.search,
          dimension2: localStorage.getItem("userId") || null,
          dimension3: mobileNumber,
          dimension4: "+91",
          dimension5: "buyer",
          dimension6: otp,
        });
        setLoading(false);
        toast.error("Invalid OTP");
      }
    } else {
      toast.error("Please enter a valid 6-digit OTP.");
      setLoading(false);
    }
  };
  return (
    <>
      <div className="login-phone-otp-container">
        <div className="login-phone-otp-header-text">
          Enter the OTP sent to {mobileNumber}
        </div>
        <div
          className="login-phone-otp-input-container"
          style={{display: "flex"}}>
          {[...Array(6)].map((_, index) => (
            <input
              key={index}
              ref={el => {
                if (el) {
                  otpInputs.current[index] = el; // Directly assign without optional chaining
                }
              }}
              type="number"
              maxLength={1}
              className="login-phone-otp-input"
              onChange={e => handleOtpChange(index, e.target.value)}
            />
          ))}
        </div>
        <button
          className="login-phone-otp-resend-text-container"
          onClick={handleResendOtp}
          disabled={time > 0}>
          {/* Resend OTP { " "} <span className="login-phone-otp-resend-text-time">{time > 0 ? `(${time})` : ""}</span> */}{" "}
          {/* Resend OTP { " "} <span className="login-phone-otp-resend-text-time">{time > 0 ? `(${time})` : ""}</span> */}
          <span
            className={`login-phone-otp-resend-text ${time > 0 ? "disabled" : ""}`}>
            Resend OTP
          </span>
          <span className="login-phone-otp-resend-text-time">
            {time > 0 ? `(${time})` : ""}
          </span>
        </button>
        <div className="login-phone-otp-continue-button-container">
          <button
            className="login-phone-otp-continue-button"
            onClick={handleVerifyOtp}
            disabled={otp.length !== 6}>
            {loading ? <Loader size={14} /> : "Continue"}
          </button>
        </div>
      </div>
    </>
  );
}
