import React, {useEffect, useRef, useState} from "react";
import "./StoreReviewCard.style.css";
import {Review} from "../../../../../features/User/UserInterface";
import {formatDistanceToNow} from "date-fns";
import StarFilledRatingSvg from "../../../../../components/SvgImages/StarFilledRatingSvg/StarFilledRatingSvg";

interface StoreReviewCardProps {
  review: Review;
  key: number;
}

const StoreReviewCard = ({review, key}: StoreReviewCardProps) => {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const reviewRef = useRef<HTMLDivElement | null>(null);
  const formattedDate = formatDistanceToNow(new Date(review.created_at), {
    addSuffix: true,
  });

  useEffect(() => {
    // Check if the text exceeds two lines
    if (reviewRef.current) {
      const isOverflowing =
        reviewRef.current.scrollHeight > reviewRef.current.clientHeight;
      setIsOverflowing(isOverflowing);
    }
  }, [review]);
  const lowerBoundRating = Math.floor(review.avg_rating);

  return (
    <div className="storefront-about-section-reviews-card" key={key}>
      <div className="storefront-about-section-reviews-card-heading">
        <div className="storefront-about-section-reviews-card-heading-rating-star">
          {Array.from({length: lowerBoundRating}).map((_, index) => (
            <StarFilledRatingSvg
              key={index}
              height={12}
              width={12}
              color="var(--content-active)"
            />
          ))}
        </div>
        <div
          ref={reviewRef}
          className="storefront-about-section-reviews-card-heading-rating-text">
          {review.review_text}
        </div>
        {isOverflowing && (
          <button className="storefront-about-section-reviews-card-heading-rating-text-read-more-button">
            Read More
          </button>
        )}
      </div>
      <div className="storefront-about-section-reviews-card-reviewer">
        <div className="storefront-about-section-reviews-card-reviewer-avatar-container">
          <img
            src={
              review.profile_photo ||
              "https://storage.googleapis.com/zoop-public-bucket/ZOOP-RECOMMENDS/productstock.png"
            }
            alt="Reviewer Profile"
            className="storefront-about-section-reviews-card-reviewer-avatar"
          />
        </div>
        <div className="storefront-about-section-reviews-card-reviewer-name-container">
          <div className="storefront-about-section-reviews-card-reviewer-name">
            {review.name}
          </div>
          <div className="storefront-about-section-reviews-card-review-date">
            {formattedDate}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoreReviewCard;
