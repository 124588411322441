import React, {useState} from "react";
import "./Header.style.css";
import {ShoppingCart} from "../../SvgImages/ShoppingCart/ShoppingCart";
import {LoginUserIcon} from "../../SvgImages/LoginUserIcon/LoginUser";
import {StaticSearchBar} from "../../SearchBar/StaticSearchBar";
import {AloliaSearch} from "../../SearchBar/AlgoliaSearch";
import {BiCategory} from "react-icons/bi";
import ZoopLogo from "../../SvgImages/ZooLogo/ZoopGreyLogo";
import Menu from "../Menu/Menu";
import {useNavigate} from "react-router";
import {menuConfig} from "../../../constants/menuConfig";
import {LoginModal} from "../../mobileV2/LoginModal/useLoginModal";
import BottomSheetV2 from "../../mobileV2/BottomSheetV2/BottomSheetV2";

interface MobileHeaderProps {
  isSignedIn: boolean;
  handleSearch: (query: string) => void;
  setIsLogoutBottomSheetOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsPopupOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const MobileHeader: React.FC<MobileHeaderProps> = ({
  isSignedIn,
  handleSearch,
  setIsLogoutBottomSheetOpen,
  setIsPopupOpen,
}) => {
  const [searchPage, setSearchPage] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="mobile-home-header" style={{flexDirection: "column"}}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          paddingTop: "2%",
        }}>
        <div style={{cursor: "pointer"}} onClick={() => navigate("/")}>
          <ZoopLogo height={50} width={100} />
        </div>

        <div
          style={{
            textAlign: "right",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "1rem",
            alignContent: "center",
          }}>
          {isSignedIn ? (
            <>
              <div
                className="header-left-container-cart"
                onClick={() => navigate("/checkout")}>
                <ShoppingCart width={30} height={30} />
                <span className="header-cart-text">Cart</span>
              </div>
              <Menu
                menu={menuConfig}
                onClick={id => {
                  if (id === "logout") {
                    setIsLogoutBottomSheetOpen(true);
                  }
                }}
              />
            </>
          ) : (
            <button
              className="header-logout-button"
              onClick={() => {
                setLoginModal(true);
              }}>
              <span style={{marginRight: "5px"}}>
                <LoginUserIcon width={20} height={20} color={"white"} />
              </span>
              Login
            </button>
          )}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          paddingBottom: "5%",
        }}>
        <div className="categories-menu" onClick={() => setIsPopupOpen(true)}>
          <BiCategory size="35px" />
        </div>

        {!searchPage ? (
          <StaticSearchBar
            onSearch={handleSearch}
            isVisible={true}
            setSearchPage={setSearchPage}
          />
        ) : (
          <AloliaSearch searchPage={searchPage} />
        )}
      </div>
      <BottomSheetV2
        isOpen={loginModal}
        onClose={() => {
          setLoginModal(false);
        }}>
        <LoginModal
          onSuccess={() => {
            setLoginModal(false);
          }}
        />
      </BottomSheetV2>
    </div>
  );
};

export default MobileHeader;
