import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Review} from "../../../features/User/UserInterface";
import StarFilledSvg from "../../../components/SvgImages/StarFilledSvg/StarFilledSvg";
import StarFilledRatingSvg from "../../../components/SvgImages/StarFilledRatingSvg/StarFilledRatingSvg";
import StarUnfilledRatingSvg from "../../../components/SvgImages/StartUnfilledRatingSvg/StarUnfilledRatingSvg";
import "./AllReviewsPage.style.css";
import {formatDistanceToNow} from "date-fns";
import {getStoreReviewsThunk} from "../../../features/User/UserSlice";
import {getStoreFrontAboutThunk} from "../../../features/User/UserSlice";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../../interceptors/store";

export const AllReviewsPage = () => {
  const {storeId} = useParams<{storeId: string}>();
  const [avgCustomerServiceRating, setAvgCustomerServiceRating] =
    useState<number>(0);
  const {storeFront} = useSelector((state: RootState) => state.user);

  const [deliveryRating, setDeliveryRating] = useState<number>(0);
  const [qualityRating, setQualityRating] = useState<number>(0);
  const [reviews, setReviews] = useState<Review[]>([]);
  const [reviewCount, setReviewCount] = useState<number>(0);
  const [, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  // const getReviews = async () => {
  //     const reviewAction = await dispatch(
  //         getStoreReviewsThunk({
  //             storeId: storeId || "",
  //         }),
  //     );
  //     if (getStoreReviewsThunk.fulfilled.match(reviewAction)) {
  //         const reviewDetails = reviewAction.payload.data.review_details;
  //         const reviewCount = reviewAction.payload.data.count;
  //         setReviews(reviewDetails);
  //         setReviewCount(reviewCount);
  //     }
  // };

  useEffect(() => {
    const fetchStoreData = async () => {
      if (storeId) {
        setLoading(true);
        try {
          // First fetch the store's about details
          await dispatch(
            getStoreFrontAboutThunk({
              storeId: storeId ?? storeId,
              type: "about",
            }),
          );

          // Then fetch reviews
          const reviewAction = await dispatch(
            getStoreReviewsThunk({
              storeId: storeId ?? storeId,
            }),
          );

          if (getStoreReviewsThunk.fulfilled.match(reviewAction)) {
            const reviewDetails = reviewAction.payload.data.review_details;
            const reviewCount = reviewAction.payload.data.count;
            setReviews(reviewDetails);
            setReviewCount(reviewCount);
            calculateAvgCustomerServiceRating(reviewDetails);
          }

          // Fetch products for the first page
        } catch (error) {
          console.error("Error fetching store data:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchStoreData();
  }, [storeId, dispatch]);

  useEffect(() => {
    calculateAvgCustomerServiceRating(reviews);
  }, [storeId]);

  //   const {reviews, reviewCount, storeRating} = useLocation().state;
  //   console.log(reviews);
  const calculateAvgCustomerServiceRating = (reviews: Review[]) => {
    const customerServiceRatings = reviews
      .flatMap(review => review.rating)
      .filter(rating => rating.rating_type === "customer_service");
    const deliveryRatings = reviews
      .flatMap(review => review.rating)
      .filter(rating => rating.rating_type === "packaging");

    const qualityRatings = reviews
      .flatMap(review => review.rating)
      .filter(rating => rating.rating_type === "quality");

    // Calculate total rating and average
    const totalCustomerServiceRating = customerServiceRatings.reduce(
      (sum, rating) => sum + rating.rating,
      0,
    );
    const avgCustomerServiceRating =
      customerServiceRatings.length > 0
        ? Math.floor(totalCustomerServiceRating / customerServiceRatings.length)
        : 0; // Avoid division by zero

    const totalDeliveryRating = deliveryRatings.reduce(
      (sum, rating) => sum + rating.rating,
      0,
    );
    const avgDeliveryRating =
      deliveryRatings.length > 0
        ? Math.floor(totalDeliveryRating / deliveryRatings.length)
        : 0;

    const totalQualityRating = qualityRatings.reduce(
      (sum, rating) => sum + rating.rating,
      0,
    );
    const avgQualityRating =
      qualityRatings.length > 0
        ? Math.floor(totalQualityRating / qualityRatings.length)
        : 0;

    setAvgCustomerServiceRating(avgCustomerServiceRating);
    setDeliveryRating(avgDeliveryRating);
    setQualityRating(avgQualityRating);
  };

  const formattedDate = (created_at: string) => {
    return formatDistanceToNow(new Date(created_at), {addSuffix: true});
  };

  return (
    <div className="all-reviews-page">
      <div className="all-reviews-page-header">
        <div className="all-reviews-page-header-rating">
          <div className="all-reviews-page-header-rating-star">
            <StarFilledSvg height={"22"} width={"22"} />
          </div>
          <div className="all-reviews-page-header-rating-text">
            {storeFront.about?.store_rating || "N/A"}
          </div>
        </div>
        <div className="all-reviews-page-header-rating-separator">•</div>
        <div className="all-reviews-page-header-rating-summary">
          {reviewCount || "N/A"} Reviews
        </div>
      </div>
      <div className="all-reviews-page-avg-rating">
        <div className="all-reviews-page-avg-rating-container">
          <div className="all-reviews-page-avg-rating-left">
            <span className="all-reviews-page-avg-rating-left-text">
              Customer Service
            </span>
          </div>
          <div className="all-reviews-page-avg-rating-right">
            <div className="all-review-page-about-section-reviews-card-heading-rating-star">
              {Array.from({length: avgCustomerServiceRating}).map(
                (_, index) => (
                  <StarFilledRatingSvg
                    key={index}
                    height={16}
                    width={16}
                    color="var(--content-active)"
                  />
                ),
              )}
              {Array.from({length: 5 - avgCustomerServiceRating}).map(
                (_, index) => (
                  <StarUnfilledRatingSvg
                    key={index}
                    height={16}
                    width={16}
                    color="var(--content-inactive)"
                  />
                ),
              )}
            </div>
            <span className="all-reviews-page-avg-rating-right-text">
              {avgCustomerServiceRating}
            </span>
          </div>
        </div>
        <div className="all-reviews-page-avg-rating-container">
          <div className="all-reviews-page-avg-rating-left">
            <span>Delivery</span>
          </div>
          <div className="all-reviews-page-avg-rating-right">
            <div className="all-review-page-about-section-reviews-card-heading-rating-star">
              {Array.from({length: deliveryRating}).map((_, index) => (
                <StarFilledRatingSvg
                  key={index}
                  height={16}
                  width={16}
                  color="var(--content-active)"
                />
              ))}
              {Array.from({length: 5 - deliveryRating}).map((_, index) => (
                <StarUnfilledRatingSvg
                  key={index}
                  height={16}
                  width={16}
                  color="var(--content-inactive)"
                />
              ))}
            </div>
            <span>{deliveryRating}</span>
          </div>
        </div>
        <div className="all-reviews-page-avg-rating-container">
          <div className="all-reviews-page-avg-rating-left">
            <span>Quality</span>
          </div>
          <div className="all-reviews-page-avg-rating-right">
            <div className="all-review-page-about-section-reviews-card-heading-rating-star">
              {Array.from({length: qualityRating}).map((_, index) => (
                <StarFilledRatingSvg
                  key={index}
                  height={16}
                  width={16}
                  color="var(--content-active)"
                />
              ))}
              {Array.from({length: 5 - qualityRating}).map((_, index) => (
                <StarUnfilledRatingSvg
                  key={index}
                  height={16}
                  width={16}
                  color="var(--content-inactive)"
                />
              ))}
            </div>
            <span>{qualityRating}</span>
          </div>
        </div>
        <div className="all-reviews-page-reviews-container">
          {reviews.map((review: Review, index: number) => (
            <div
              className="all-review-page-about-section-reviews-card"
              key={index}>
              <div className="all-review-page-about-section-reviews-card-heading">
                <div className="all-review-page-about-section-reviews-card-heading-rating-star">
                  {Array.from({length: Math.floor(review.avg_rating)}).map(
                    (_, index) => (
                      <StarFilledRatingSvg
                        key={index}
                        height={12}
                        width={12}
                        color="var(--content-active)"
                      />
                    ),
                  )}
                </div>
                <div className="all-review-page-about-section-reviews-card-heading-rating-text">
                  {review.review_text}
                </div>
              </div>
              <div className="all-review-page-about-section-reviews-card-reviewer">
                <div className="all-review-page-about-section-reviews-card-reviewer-avatar-container">
                  <img
                    src={
                      review.profile_photo ||
                      "https://storage.googleapis.com/zoop-public-bucket/ZOOP-RECOMMENDS/productstock.png"
                    }
                    alt="Reviewer Profile"
                    className="all-review-page-about-section-reviews-card-reviewer-avatar"
                  />
                </div>
                <div className="all-review-page-about-section-reviews-card-reviewer-name-container">
                  <div className="all-review-page-about-section-reviews-card-reviewer-name">
                    {review.name}
                  </div>
                  <div className="all-review-page-about-section-reviews-card-review-date">
                    {formattedDate(review.created_at)}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
